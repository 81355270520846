import axios from 'axios';
const TOKEN = 'Bearer 26d460b39301781b10d37af31e2e5a4a';

export default {

    async getPresenze(userID: string) {
        const data = new FormData();
        data.append("limit", "50");
        data.append("offset", "0");
        data.append("where[presenze_user]", userID.toString());
        data.append("orderby", "presenze_creation_date");
        data.append("orderdir", "desc");

        const response = await axios.post('search/presenze', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        localStorage.setItem('presenze', JSON.stringify(response.data.data));
        //console.log(response);
        return response.data.data;
    },

    async getPresenzaDetail(id: string) {
        const data = new FormData();
        data.append("where[presenze_id]", id.toString());

        const response = await axios.post('search/presenze', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    /**
     * 
     * @param intervento array with intervento data
     * @returns intervento just created
     * 
     * Salva intervento appena creato
     */
    async savePresenza(presenza) {
        const response = await axios.post(`create/presenze`, presenza, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
}