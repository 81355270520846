<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio presenza</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <!-- <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header> -->

      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>

      <div v-else>
        <ion-card>
          <ion-card-header class="pb-0 bg-primary">
            <ion-card-title>
              <ion-text color="light">
                <p class="card_title">
                  Presenza del
                  {{ dateFormat(presenza.presenze_data_ora_ingresso) }}
                </p>
              </ion-text>
            </ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <div class="intervento">
              <ion-list>
                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Azienda</ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{ checkField(presenza.aziende_ragione_sociale) }}
                    </p>
                  </ion-text>
                </ion-item>

                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Cliente</ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{ riferimentoCliente(presenza) }}
                    </p>
                  </ion-text>
                </ion-item>

                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Sede</ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{
                        checkField(presenza.customers_shipping_address_city)
                      }}
                      {{ checkField(presenza.customers_shipping_address_nome) }}
                    </p>
                  </ion-text>
                </ion-item>

                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Reparto</ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{ checkField(presenza.reparto_nome) }}
                    </p>
                  </ion-text>
                </ion-item>

                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Data e ora ingresso</ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{ dateFormat(presenza.presenze_data_ora_ingresso) }}
                    </p>
                  </ion-text>
                </ion-item>

                <ion-item class="ion-no-padding">
                  <ion-label color="primary"> Data e ora uscita</ion-label>
                  <ion-text>
                    <p class="base_text">
                      {{ dateFormat(presenza.presenze_data_ora_uscita) }}
                    </p>
                  </ion-text>
                </ion-item>

                <ion-item class="ion-no-padding">
                  <div>
                    <h3 class="description_title">Note</h3>

                    <p class="base_text">
                      {{ checkField(presenza.presenze_note) }}
                    </p>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonText,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonList,
  IonItem,
  onIonViewWillEnter,
} from "@ionic/vue";
import { checkmark } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import apiPresenze from "../services/presenze";
import { openToast } from "../services/toast";

import moment from "moment";

export default {
  name: "Tab2",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonText,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonList,
    IonItem,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id as string; //presenza_id by route params
    const loading = ref(false);
    const presenza = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    //let localInterventi = JSON.parse(localStorage.getItem("interventi"));
    //console.log(localInterventi);

    function riferimentoCliente(presenza) {
      if (presenza.customers_company) {
        return `${presenza.customers_company}`;
      } else {
        return `${presenza.customers_name} ${presenza.customers_last_name}`;
      }
    }

    /**
     *
     * Return '-' if field is null
     *
     */
    function checkField(field) {
      if (field == null || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (moment.isDate(new Date(date))) {
        return (
          moment(date).format("DD/MM/YYYY") +
          " - " +
          moment(date).format("HH:mm")
        );
      } else {
        return "-";
      }
    }

    function getIntervento() {
      loading.value = true;
      apiPresenze
        .getPresenzaDetail(id)
        .then((response) => {
          presenza.value = response;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta della presenza", "danger");
        })
        .finally(() => {
          loading.value = false;
        });
    }

    onIonViewWillEnter(() => {
      getIntervento();
    });

    onMounted(() => {
      //getIntervento();
    });

    return {
      loading,
      presenza,
      riferimentoCliente,
      dateFormat,
      checkField,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base_text {
  font-size: 14px;
  /*color: #000000 !important;*/
}
.pb-0 {
  padding-bottom: 0;
}

.description_title {
  color: var(--ion-color-primary, #3880ff) !important;
  margin: 10px 0;
}

.bg-primary {
  background: var(--ion-color-primary, #3880ff) !important;
  padding: 8px;
  color: #ffffff;
}

.card_title {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.btn_actions {
  display: flex;
  justify-content: space-between;
}
.btn_action {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}
</style>